import { combineReducers } from 'redux'

import auth from './auth'
import users from './users'
import orders from './orders'
import stockb from './stockb'
import regions from './regions'
import shipping from './shipping'
import settings from './settings'
import products from './products'
import discounts from './discounts'
import customers from './customers'
import salesReport from './salesReport'
import attendances from './attendances'
import transactions from './transactions'
import disbursements from './disbursements'
import chartOfAccounts from './chartOfAccounts'
import commissionDisbursements from './commissionDisbursements'
import notifications from './notifications'

export default combineReducers({
  auth,
  users,
  stockb,
  orders,
  regions,
  settings,
  products,
  shipping,
  discounts,
  customers,
  salesReport,
  attendances,
  transactions,
  disbursements,
  chartOfAccounts,
  commissionDisbursements,
  notifications
})
