import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

import App from './App'
import store from './redux/store'

import './index.css'
import './config/firebase'

// Create a root with React 18 API
const root = ReactDOM.createRoot(document.getElementById('root'), { concurrent: true })

// Render your app
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
reportWebVitals()

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope)
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error)
      })
  })
}
