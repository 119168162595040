/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'

export const ORDER_TYPES = {
  ORDER_MODIFICATIOS_FETCH: 'ORDER_MODIFICATIOS_FETCH',
  ORDERS_FETCHED: 'ORDERS_FETCHED',
  ORDER_FETCHED: 'ORDER_FETCHED',
  SET_LOADING: 'SET_LOADING',
  ENUMS_FETCHED: 'ENUMS_FETCHED',
  ERROR: 'ERROR',
  INFO: 'INFO',
  // HEADERS_FETCHED
}

const initialState = Immutable({
  order: {},
  list: [],
  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
  loading: true,
  allEnums: [],
  aggregation: {},
  error: null,
  info: null,
  modifications: { count: 0, data: [] },
  // pageInfo: { next: null, prev: null }
})

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_TYPES.ORDERS_FETCHED:
      const { limit, offset, data, pages, page } = action.payload
      const { ordersCount, orders, filterCount } = data
      const {
        count,
        sum: { boorranGrandTotal, boorranDeliveryPrice, grandTotal, discount },
      } = ordersCount.aggregate
      const {
        pending,
        unpaidCash,
        unpaidBank,
        unpack,
        unship,
        shipping,
        delivered,
        completed,
        waiting,
        assignedDeliver,
        successDeliver,
        completedDeliver,
        pendingByStaff,
      } = filterCount

      return state
        .set('list', orders)
        .set('pagination', { limit, offset, pages, page, count })
        .set('aggregation', {
          sum: boorranGrandTotal,
          sumDelivery: boorranDeliveryPrice,
          sumShopify: grandTotal,
          sumDiscount: discount,
          sumPending: pending,
          sumUnpaidCash: unpaidCash,
          sumUnpaidBank: unpaidBank,
          sumUnpack: unpack,
          sumUnship: unship,
          sumShipping: shipping,
          sumDelivered: delivered,
          sumCompleted: completed,
          sumWaiting: waiting,
          sumAssignDeliver: assignedDeliver,
          sumSuccessDeliver: successDeliver,
          sumCompletedDeliver: completedDeliver,
          sumPendingByStaff: pendingByStaff,
        })
        .set('loading', false)

    case ORDER_TYPES.ORDER_FETCHED:
      return state.set('order', action.payload).set('loading', false)

    case ORDER_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    case ORDER_TYPES.ENUMS_FETCHED:
      return state.set('allEnums', { ...state.allEnums, ...action.payload })

    case ORDER_TYPES.ERROR:
      return state.set('error', action.payload)

    case ORDER_TYPES.INFO:
      return state.set('info', action.payload)

    case ORDER_TYPES.ORDER_MODIFICATIOS_FETCH:
      return state
        .setIn(['modifications', 'data'], action.payload.requests)
        .setIn(['modifications', 'count'], action.payload.count)

    default:
      return state
  }
}

// import { createActions, handleActions } from 'redux-actions';
//
// const defaultState = { order: null, list: [], pagination:{ limit: 20, offset: 0, count: 0 }, pageInfo: { next: null, prev: null } };
//
// export const { clearOrders, ordersFetched, headersFetched, orderFetched } = createActions({
//   CLEAR_ORDERS: json => json,
//   ORDERS_FETCHED: json => json,
//   HEADERS_FETCHED: json => json,
//   ORDER_FETCHED: json => json
// });
//
// export const reducer = handleActions(
//   {
//     [clearOrders]: (state, { payload }) => { return defaultState; },
//     [ordersFetched]: (state, { payload }) => {
//       const { limit, offset, data } = payload;
//
//       return {
//         ...state,
//         list: data.orders,
//         pagination: { ...state.pagination, limit, offset, count: data.ordersCount.aggregate.count }
//       };
//     },
// [headersFetched]: (state, { payload }) => {
//   const { link } = payload.headers || {};
//
//   const arrLinks = link[0].split(';');
//   const hasNextAndPrev = arrLinks.length > 2;
//
//   const getPageInfo = str => str.replace(/.*page\_info\=|\>$/ig, '');
//
//   let next = hasNextAndPrev
//     ? getPageInfo(arrLinks[1].split(',')[1])
//     : getPageInfo(arrLinks[0]);
//   let prev = hasNextAndPrev
//     ? getPageInfo(arrLinks[0])
//     : null;
//
//   return { ...state, pageInfo: { next, prev } };
// },
//     [orderFetched]: (state, { payload }) => {
//       return { ...state, order: payload };
//     }
//   },
//   defaultState,
// );
