import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

import { HASURA_URL, HASURA_KEY, HASURA_WSS } from '../redux/config'

// HTTP link for queries and mutations
const httpLink = createHttpLink({
  uri: HASURA_URL,
})

// Authentication context for adding headers
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-hasura-admin-secret': HASURA_KEY,
    },
  }
})

// WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(
  createClient({
    url: HASURA_WSS,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          'x-hasura-admin-secret': HASURA_KEY,
        },
      },
    },
  })
)

// Split links: route subscriptions through WebSocket and others through HTTP
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink)
)

// Apollo Client setup
export default new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          boorran_Products: {
            keyArgs: [],
            merge: (existing = [], incoming) => {
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
