import React, { Suspense, lazy } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createSelector } from 'reselect'
import { ColorContextProvider } from './theme'
import client from './config/apollo'
import useAuthState from './hook/useAuthState'

// Lazy load routes and other components
const Routes = lazy(() => import('./routes'))
const Loading = lazy(() => import('./components/Loading'))
const Error = lazy(() => import('./components'))

// Memoized selector
const selectAuth = createSelector(
  (state) => state.auth,
  (auth) => ({
    error: auth.error && Object.keys(auth.error).length ? auth.error : null,
    authenticated: Boolean(auth.data && Object.keys(auth.data).length),
    loading: auth.loading,
  })
)

const App = React.memo(() => {
  const dispatch = useDispatch()
  const { error, loading } = useAuthState(selectAuth, dispatch)

  if (loading) return <Loading text="Signing you in..." />
  if (error) return <Error message={error.message} />

  return (
    <ApolloProvider client={client}>
      <ColorContextProvider>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        </StyledEngineProvider>
      </ColorContextProvider>
    </ApolloProvider>
  )
})

export default App
