import { isEmpty, sumBy } from 'lodash'

const sumOrderItems = (orderItems) => {
  if (isEmpty(orderItems)) return 0

  return sumBy(Object.values(orderItems), (it) => Number(it.price * it.quantity)).toFixed(2)
}

const calculateDiscount = (subTotal, discount, discountType) => {
  if (discountType === '%') {
    const discountAmount = (discount || 0) / 100

    return (subTotal * discountAmount).toFixed(2)
  }

  return parseFloat(discount || 0.0).toFixed(2)
}

const getOrderSummary = (
  orderItems = {},
  discountAmount = 0,
  shipping = 0,
  freeDelivery = false,
  discountType = '$'
) => {
  const subTotal = sumOrderItems(orderItems)
  const discount = calculateDiscount(subTotal, discountAmount, discountType)
  const deliveryFee = freeDelivery ? 0 : shipping
  const total = Number(subTotal - discount + deliveryFee).toFixed(2)

  return {
    subTotal,
    discount,
    shipping,
    total,
  }
}

export default {
  getOrderSummary,
  calculateDiscount,
}
