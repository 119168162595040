import Immutable from 'seamless-immutable'

export const CHART_OF_ACCOUNT_TYPES = {
  CHART_OF_ACCOUNTS_ENUM_FETCHED: 'CHART_OF_ACCOUNTS_ENUM_FETCHED',
  CHART_OF_ACCOUNTS_FETCHED: 'CHART_OF_ACCOUNTS_FETCHED',
  CHART_OF_ACCOUNT_FETCHED: 'CHART_OF_ACCOUNT_FETCHED',
  SET_LOADING: 'SET_CHART_OF_ACCOUNT_LOADING',
}

const initialState = Immutable({
  data: {},
  formData: {},
  enums: [],
  loading: false,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNTS_ENUM_FETCHED:
      return state.set('enums', action.payload)

    case CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNTS_FETCHED:
      return state.set('data', action.payload).set('loading', false)

    case CHART_OF_ACCOUNT_TYPES.CHART_OF_ACCOUNT_FETCHED:
      return state.set('formData', action.payload).set('loading', false)

    case CHART_OF_ACCOUNT_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    default:
      return state
  }
}

export default reducer
