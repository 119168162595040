/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'

export const DISBURSEMENT_TYPES = {
  DISBURSEMENTS_navigate_FETCHED: 'DISBURSEMENTS_navigate_FETCHED',
  DISBURSEMENT_navigate_FETCHED: 'DISBURSEMENT_navigate_FETCHED',
  DISBURSEMENTS_FETCHED: 'DISBURSEMENTS_FETCHED',
  DISBURSEMENT_FETCHED: 'DISBURSEMENT_FETCHED',
  SET_LOADING: 'SET_LOADING',
  ENUMS_FETCHED: 'ENUMS_FETCHED',
  ERROR: 'ERROR',
  INFO: 'INFO',
  // HEADERS_FETCHED
}

const initialState = Immutable({
  disbursement: {},
  disbursements: { pendingDisbursements: [], finishedDisbursements: [] },
  list: {
    paymentReceiver: [],
    refundByList: [],
    refundByCount: 0,
    paymentReceiverCount: 0,
    income: 0,
    deliveryExpense: 0,
  },
  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
  loading: true,
  allEnums: [],
  aggregation: {},
  error: null,
  info: null,
  // pageInfo: { next: null, prev: null }
})

export default (state = initialState, action) => {
  switch (action.type) {
    case DISBURSEMENT_TYPES.DISBURSEMENT_navigate_FETCHED:
      const result = action?.payload?.data
      return state.set('disbursement', result).set('loading', false)

    case DISBURSEMENT_TYPES.DISBURSEMENTS_navigate_FETCHED:
      const { data, limit, offset, pages, page } = action.payload
      const { pendingDisbursements, finishedDisbursements } = data

      return state
        .set('disbursements', { pendingDisbursements, finishedDisbursements })
        .set('pagination', {
          limit,
          offset,
          page,
          pages: pages || state.pagination.pages,
          count: data.finishedDisbursements.length,
        })
        .set('loading', false)

    case DISBURSEMENT_TYPES.DISBURSEMENTS_FETCHED:
      const {
        data: { orders },
      } = action.payload

      return state.set('list', orders).set('loading', false)

    case DISBURSEMENT_TYPES.DISBURSEMENT_FETCHED:
      return state.set('order', action.payload).set('loading', false)

    case DISBURSEMENT_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    case DISBURSEMENT_TYPES.ENUMS_FETCHED:
      return state.set('allEnums', { ...state.allEnums, ...action.payload })

    case DISBURSEMENT_TYPES.ERROR:
      return state.set('error', action.payload)

    case DISBURSEMENT_TYPES.INFO:
      return state.set('info', action.payload)

    default:
      return state
  }
}
