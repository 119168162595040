import Immutable from 'seamless-immutable'

export const REPORT_TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_TOTAL_SALES: 'SET_TOTAL_SALES',
  SET_TOTAL_SALES_BY_ADMIN: 'SET_TOTAL_SALES_BY_ADMIN',
  SET_TOTAL_RECEIVED: 'SET_TOTAL_RECEIVED',
  SET_TOTAL_UNPAID: 'SET_TOTAL_UNPAID',
  SET_TOTAL_CANCELLED: 'SET_TOTAL_CANCELLED',
  SET_TOTAL_EXCHANGED: 'SET_TOTAL_EXCHANGED',
  SET_TOTAL_PENDING: 'SET_TOTAL_PENDING',
}

const initialState = Immutable({
  totalSalesByAdmin: {},
  totalSales: {},
  totalReceived: {},
  totalUnpaid: {},
  totalCancelled: {},
  totalExchanged: {},
  totalPending: {},
  loading: false,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_TYPES.SET_TOTAL_SALES:
      return state.set('totalSales', action.payload)
    case REPORT_TYPES.SET_TOTAL_SALES_BY_ADMIN:
      return state.set('totalSalesByAdmin', action.payload)

    case REPORT_TYPES.SET_TOTAL_RECEIVED:
      return state.set('totalReceived', action.payload)

    case REPORT_TYPES.SET_TOTAL_UNPAID:
      return state.set('totalUnpaid', action.payload)

    case REPORT_TYPES.SET_TOTAL_CANCELLED:
      return state.set('totalCancelled', action.payload)

    case REPORT_TYPES.SET_TOTAL_EXCHANGED:
      return state.set('totalExchanged', action.payload)

    case REPORT_TYPES.SET_TOTAL_PENDING:
      return state.set('totalPending', action.payload)

    case REPORT_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    default:
      return state
  }
}

export default reducer
