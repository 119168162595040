/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'

export const CUSTOMER_TYPES = {
  CUSTOMER_FETCHED: 'CUSTOMER_FETCHED',
  CUSTOMERS_SEARCH: 'CUSTOMERS_SEARCH',
  CUSTOMERS_FETCHED: 'CUSTOMERS_FETCHED',

  GET_CUSTOMER_ORDERS: 'GET_CUSTOMER_ORDERS',
  GET_CUSTOMER_ORDERS_COUNT: 'GET_CUSTOMER_ORDERS_COUNT',
  GET_CUSTOMER_ORDERS_SUCCESS_COUNT: 'GET_CUSTOMER_ORDERS_SUCCESS_COUNT',
  GET_CUSTOMER_ORDERS_SUCCESS_AMOUNT: 'GET_CUSTOMER_ORDERS_SUCCESS_AMOUNT',
}

const initialState = Immutable({
  list: [],
  search: [],

  info: {},
  orders: [],
  customer: {},

  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
})

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_TYPES.CUSTOMERS_FETCHED:
      const { limit, offset, data, pages, page } = action.payload
      return state.set('list', data.customers).set('pagination', {
        limit,
        offset,
        pages,
        page,
        count: data.customerCount.aggregate.count,
      })
    // .set('loading', false);
    case CUSTOMER_TYPES.CUSTOMERS_SEARCH:
      return state.set('search', action.payload)
    case CUSTOMER_TYPES.CUSTOMER_FETCHED:
      return state.set('customer', action.payload)
    // .set('loading', false);
    case CUSTOMER_TYPES.GET_CUSTOMER_ORDERS_COUNT:
      return state.setIn(['info', action.payload.customerId, 'orders_count'], action.payload.count)
    case CUSTOMER_TYPES.GET_CUSTOMER_ORDERS_SUCCESS_COUNT:
      return state.setIn(
        ['info', action.payload.customerId, 'orders_success_count'],
        action.payload.count
      )
    case CUSTOMER_TYPES.GET_CUSTOMER_ORDERS_SUCCESS_AMOUNT:
      return state
        .setIn(['info', action.payload.customerId, 'spending_amount'], action.payload.total_amount)
        .setIn(
          ['info', action.payload.customerId, 'total_avg_order'],
          action.payload.total_avg_order
        )
    case CUSTOMER_TYPES.GET_CUSTOMER_ORDERS:
      return state.set('orders', action.payload.orders)
    default:
      return state
  }
}
