const fields = `
  id message start end created_at updated_at type description channel
`

const qNotifications = `
  query {
    boorran_Notifications {${fields}}
  }
`

const insertNotification = `
  mutation ($request: [boorran_Notifications_insert_input!]!) {
    insert_boorran_Notifications(objects: $request) {
      returning {${fields}}
    }
  }
`

const deleteNotification = `
  mutation ($id: uuid) {
    delete_boorran_Notifications(where: { id: { _eq: $id } }) {
      returning {${fields}}
    }
  }
`

const updateNotification = `
  mutation ($id: uuid, $request: boorran_Notifications_set_input) {
    update_boorran_Notifications(where: { id: { _eq: $id } }, _set: $request) {
      returning {${fields}}
    }
  }
`

const qTodayNotifications = `
   query($today: timestamptz) {
    boorran_Notifications(where: { _and: [
      { start: { _lte: $today } },
      { end: { _gte: $today } },
    ]}) {
      ${fields}
    }
  }
`

export {
  qNotifications,
  insertNotification,
  deleteNotification,
  updateNotification,
  qTodayNotifications,
}
