/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-unused-vars */
import get from 'lodash/get'
import gq from '../queries'
import { setEnums } from './orders'
import { SETTINGS_TYPES } from '../reducers/settings'
import {
  qDeliverer,
  qCheckoutUrl,
  qExchangeRate,
  qUpdateDelivery,
  qUpdateExchange,
  qDeliveryConfig,
  qFetchDeliveryPrice,
  qUpdateDeliveryConfig,
  qUpdateAttendanceConfig,
  qGetDiscountOnlinePayment,
  qUpdateDeliverPricebyUser,
  qUpdateDeliveryPricePartner,
  qUpdateDiscountOnlinePayment,
} from '../../queries/settings'
// import { requestAttendanceConfig } from './attendances'
import utils from '../../utils'

export const updateSetting = (obj) => async (dispatch) => {
  if (!obj || !Object.keys(obj).length) return

  return new Promise((resolve, reject) => {
    gq.gqRequest(gq.qUpsert('Enums', Object.keys(obj), 'Enums_pkey'), { obj })
      .then((res) => {
        dispatch(setEnums())
        resolve(res)
      })
      .catch((err) => {
        console.error(err)
        reject('Error')
      })
  })
}

export const setSingleSetting = (id) => async (dispatch, getState) => {
  if (!id) return dispatch({ type: SETTINGS_TYPES.SETTING_FETCHED, payload: {} })

  const {
    settings: { list },
  } = getState()
  const payload = list.find(({ id: itemId }) => itemId === id)

  dispatch({ type: SETTINGS_TYPES.SETTING_FETCHED, payload })
}

export const getExchangeRate = () => async (dispatch) => {
  await gq.gqRequest(qExchangeRate).then(({ exchangeRate }) => {
    dispatch({
      type: SETTINGS_TYPES.SETTING_EXCHANGERATE,
      payload: exchangeRate[0],
    })
  })
}

export const updateExchangeRate =
  ({ id, riel }) =>
  async (dispatch) => {
    await gq
      .gqRequest(qUpdateExchange, { id, riel })
      .then(({ update_Enums_ExchangeRate: { returning } }) => {
        dispatch({
          type: SETTINGS_TYPES.SETTING_EXCHANGERATE,
          payload: returning[0],
        })
      })
  }

export const fetchDeliverer = () => async (dispatch) => {
  await gq.gqRequest(qDeliverer).then((res) => {
    const partner = res.partner?.map((eachPartner) => ({
      id: eachPartner.id,
      name: eachPartner.text,
      value: eachPartner.value,
      deliveryPrice: eachPartner.metadata?.deliveryPrice,
      type: 'enum',
    }))
    const deliverer = res.deliverer?.map((each) => ({
      id: each.id,
      name: each.name,
      value: null,
      deliveryPrice: each.metadata?.deliveryPrice,
      type: 'user',
    }))
    const deliveryPrices = [].concat(deliverer, partner)
    dispatch({
      type: SETTINGS_TYPES.SETTING_DELIVERYPRICE,
      payload: deliveryPrices,
    })
  })
}

export const updateDeliveryPrice = (payload) => {
  const { id, deliveryPrice, type } = payload
  const query = type === 'user' ? qUpdateDeliverPricebyUser : qUpdateDeliveryPricePartner
  return gq.gqRequest(query, { id, metadata: { deliveryPrice } })
}

// will merge between driver use and deliveryprice enums
export const updateEnumsDeliveryPrice = (obj) => async () => {
  await gq.gqRequest(qUpdateDelivery, { obj })
}

export const fetchDeliveryPrice = (refId) => async (dispatch) => {
  await gq.gqRequest(qFetchDeliveryPrice, { refId }).then(({ deliveryPrice }) => {
    dispatch({
      type: SETTINGS_TYPES.SETTING_DELIVERYPRICE,
      payload: deliveryPrice,
    })
  })
}

export const fetchDeliveryConfig = () => async (dispatch) => {
  await gq.gqRequest(qDeliveryConfig).then(({ boorran_Configs }) => {
    const configs = boorran_Configs.reduce(
      (result, config) => Object.assign(result, { [config.key]: config.value }),
      {}
    )

    dispatch({
      type: SETTINGS_TYPES.SETTING_DELIVERYCONFIG,
      payload: configs,
    })
  })
}

export const updateDeliveryConfig =
  ({ key, value }) =>
  async (dispatch, getState) => {
    try {
      const { deliveryConfigs } = getState().settings

      await gq.gqRequest(qUpdateDeliveryConfig, { key, value })
      dispatch({
        type: SETTINGS_TYPES.SETTING_DELIVERYCONFIG,
        payload: { ...deliveryConfigs, delivery: value },
      })
    } catch (error) {
      console.log('updateDeliveryConfig', error)
    }
  }

export const getCheckoutUrl = () => (dispatch) => {
  gq.gqRequest(qCheckoutUrl).then(({ boorran_Configs }) => {
    dispatch({
      type: SETTINGS_TYPES.SETTING_CHECKOUT_URL,
      url: boorran_Configs[0].value.url,
    })
  })
}

export const getDiscoutnOnlinePayment = () => (dispatch) => {
  gq.gqRequest(qGetDiscountOnlinePayment).then(({ Enums }) => {
    dispatch({
      type: SETTINGS_TYPES.SETTING_DISCOUNT_ONLINE_PAYMENT,
      payload: Enums[0],
    })
  })
}

export const updateDiscountOnlinePayment = (obj) => (dispatch) => {
  gq.gqRequest(qUpdateDiscountOnlinePayment, { obj }).then(({ update_Enums: { returning } }) => {
    dispatch({
      type: SETTINGS_TYPES.SETTING_CHECKOUT_URL,
      payload: returning[0],
    })
  })
}

// export const getAttedanceConfig = () => async (dispatch) => {
//   try {
//     const result = await requestAttendanceConfig()

//     dispatch({
//       type: SETTINGS_TYPES.SETTING_ATTENDANCE,
//       payload: result,
//     })
//   } catch (err) {
//     console.log(err)
//   }
// }

export const updateAttendanceConfig = (updateObj) => async (dispatch) => {
  try {
    const request = await gq.gqRequest(qUpdateAttendanceConfig, { obj: updateObj })

    const {
      update_boorran_Configs: { returning },
    } = request

    dispatch({
      type: SETTINGS_TYPES.SETTING_ATTENDANCE,
      payload: returning[0].value,
    })
  } catch (err) {
    console.log(err)
  }
}

export const getDefaultLocation = () => async (dispatch) => {
  try {
    const {
      boorran_Configs: [defaultLocation],
    } = await gq.gqRequest(`{
      boorran_Configs(where: { key: { _eq: "shopLocation" } }) {
        id
        key
        value
      }
    }`)

    dispatch({
      type: SETTINGS_TYPES.SETTING_DEFAULT_LOCATION,
      payload: {
        lat: Number(get(defaultLocation, 'value.lat', utils.constants.defaultLocation.lat)),
        lng: Number(get(defaultLocation, 'value.lng', utils.constants.defaultLocation.lng)),
      },
    })
  } catch (err) {
    console.log(err)
  }
}
