import Immutable from 'seamless-immutable'

export const COMMISSION_DISBURSEMENT_TYPES = {
  PENDING_COMMISSION_DISBURSEMENTS_FETCHED: 'PENDING_COMMISSION_DISBURSEMENTS_FETCHED',
  UNDISBURSED_COMMISSIONS_FETCHED: 'UNDISBURSED_COMMISSIONS_FETCHED',
  SET_LOADING: 'SET_COMMISSION_DISBURSEMENT_LOADING',

  FETCH_HISTORY_COMMISSION_DISBURSEMENTS: 'FETCH_HISTORY_COMMISSION_DISBURSEMENTS',
  HISTORY_COMMISSION_DISBURSEMENTS_FETCHED: 'HISTORY_COMMISSION_DISBURSEMENTS_FETCHED',
  COMMISSION_DISBURSEMENT_DETAIL_FETCHED: 'COMMISSION_DISBURSEMENT_DETAIL_FETCHED',

  COMMISSION_DISBURSEMENT_COLLECTIONS_FETCHED: 'COMMISSION_DISBURSEMENT_COLLECTIONS_FETCHED',
}

const initialState = Immutable({
  loading: false,
  pending: [],
  undisbursedCommissions: {
    agg: { itemCount: 0, totalCommission: 0 },
    collectionIds: [],
    commissions: [],
  },
  history: {
    data: [],
    pagination: { page: 1, totalPage: 1 },
    loading: false,
  },
  detail: {},
  collections: [],
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMISSION_DISBURSEMENT_TYPES.PENDING_COMMISSION_DISBURSEMENTS_FETCHED:
      return state.set('pending', action.payload).set('loading', false)

    case COMMISSION_DISBURSEMENT_TYPES.UNDISBURSED_COMMISSIONS_FETCHED:
      return state
        .setIn(['undisbursedCommissions', 'agg'], action.agg)
        .setIn(['undisbursedCommissions', 'commissions'], action.commissions)
        .setIn(['undisbursedCommissions', 'collectionIds'], action.collectionIds)

    case COMMISSION_DISBURSEMENT_TYPES.FETCH_HISTORY_COMMISSION_DISBURSEMENTS:
      return state
        .setIn(['history', 'loading'], true)
        .setIn(['history', 'pagination', 'page'], action.payload.page)

    case COMMISSION_DISBURSEMENT_TYPES.HISTORY_COMMISSION_DISBURSEMENTS_FETCHED:
      return state
        .setIn(['history', 'loading'], false)
        .setIn(['history', 'data'], action.payload.data)
        .setIn(['history', 'pagination'], action.payload.pagination)

    case COMMISSION_DISBURSEMENT_TYPES.COMMISSION_DISBURSEMENT_DETAIL_FETCHED:
      return state.setIn(['detail', action.payload.id], action.payload)

    case COMMISSION_DISBURSEMENT_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    case COMMISSION_DISBURSEMENT_TYPES.COMMISSION_DISBURSEMENT_COLLECTIONS_FETCHED:
      return state.set('collections', action.payload)

    default:
      return state
  }
}

export default reducer
