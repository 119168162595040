import { POST, wsGQL, wsGQLclose } from 'fetchier'
import Cookie from 'js-cookie'
import { AUTH_TYPES } from '../types'
import { formatEnums, HASURA_KEY, HASURA_WSS, NODE_URL } from '../config'
import { ORDER_TYPES } from '../reducers/orders'
import { fetchUsers, setEnums } from './orders'
import { getTodayNotification } from './notifications'
import { getTodayAttendance } from './attendances'
import { getDefaultLocation } from './settings'

// Helper function to handle errors
const handleError = (dispatch, err) => {
  dispatch(setLoading(false))
  dispatch({ type: AUTH_TYPES.SET_ERROR, payload: err })
  console.error(err)
}

// Action to set loading state
const setLoading = (loading) => ({
  type: AUTH_TYPES.SET_LOADING,
  loading,
})

// Action to set user data
const setData = (data) => ({
  type: AUTH_TYPES.SET_DATA,
  data,
})

const login = (body, callback) => async (dispatch) => {
  dispatch(setLoading(true))

  const req = { url: `${NODE_URL}/auth` }
  const isUUID = typeof body === 'string' && body.split('-').length === 5

  if (!isUUID && (!body.email || !body.pin)) {
    return dispatch(handleError('Not enough credential'))
  }

  // Set Authorization header or body based on input type
  if (isUUID) {
    req.headers = { Authorization: body }
  } else {
    req.body = body
  }

  try {
    const {
      data: {
        session: { id, user },
      },
    } = await POST(req)
    Cookie.set('sessionToken', id, { expires: 365 })

    const usersResponse = await Promise.all([
      dispatch(fetchUsers()),
      dispatch(setEnums()),
      dispatch(initWs()),
      // dispatch(requestIpAddress()),
      dispatch(getDefaultLocation()),
    ])

    const [{ Users }] = usersResponse

    dispatch(setData(user))
    dispatch(getTodayAttendance())
    dispatch(getTodayNotification())

    // Filter and format users for order enums
    const filteredUsers = Users.filter(({ activationStatus }) => activationStatus).map(
      ({ id, name, role, metadata }) => ({
        id,
        type: 'users',
        text: name,
        value: id,
        role,
        metadata,
      })
    )

    dispatch({
      type: ORDER_TYPES.ENUMS_FETCHED,
      payload: formatEnums(filteredUsers),
    })

    if (callback) callback()
  } catch (err) {
    handleError(dispatch, err)
    dispatch(setData({}))
  }
}

// Authenticate user session from cookie
const authenticate = () => async (dispatch) => {
  const sessionToken = Cookie.get('sessionToken')
  if (sessionToken) {
    return dispatch(login(sessionToken))
  }
  return dispatch(setData({}))
}

// Initialize WebSocket connection for GraphQL
const initWs = () => async () => {
  try {
    await wsGQL({
      url: HASURA_WSS,
      headers: { 'x-hasura-admin-secret': HASURA_KEY },
    })
  } catch (err) {
    console.error('WebSocket initialization failed:', err)
  }
}

// Logout action and WebSocket close
const logout = () => async (dispatch) => {
  dispatch(setLoading(true))

  try {
    Cookie.remove('sessionToken')
    await wsGQLclose()
    dispatch(setData({}))
  } catch (err) {
    handleError(dispatch, err)
  }
}

// Reset pin for user
const resetPin = async (data) => {
  const sessionToken = Cookie.get('sessionToken')
  const req = {
    url: `${NODE_URL}/auth/reset-pin`,
    body: data,
    headers: { Authorization: sessionToken },
  }

  try {
    const res = await POST(req)
    console.log('Pin reset successful:', res)
  } catch (err) {
    console.error('Pin reset failed:', err)
  }
}

export default {
  login,
  error: handleError,
  logout,
  setData,
  resetPin,
  setLoading,
  authenticate,
}
