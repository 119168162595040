/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'

export const SETTINGS_TYPES = {
  SETTINGS_FETCHED: 'SETTINGS_FETCHED',
  SETTING_FETCHED: 'SETTING_FETCHED',
  SETTING_IPADDRESS: 'SETTING_IPADDRESS',
  SETTING_ATTENDANCE: 'SETTING_ATTENDANCE',
  SETTING_CHECKOUT_URL: 'SETTING_CHECKOUT_URL',
  SETTING_EXCHANGERATE: 'SETTING_EXCHANGERATE',
  SETTING_DELIVERYPRICE: 'SETTIN_DELIVERYPRICE',
  SETTING_DELIVERYCONFIG: 'SETTING_DELIVERYCONFIG',
  SETTING_DEFAULT_LOCATION: 'SETTING_DEFAULT_LOCATION',
  SETTING_UPDATE_ATTENDANCE: 'SETTING_UPDATE_ATTENDANCE',
  SETTING_DISCOUNT_ONLINE_PAYMENT: 'SETTING_DISCOUNT_ONLINE_PAYMENT',
}

const initialState = Immutable({
  list: [],
  setting: {},
  attendance: {},

  exchangeRate: {},
  deliveryPrice: [],
  deliveryConfigs: {},
  defaultLocation: {},

  discountOnlinePayment: {},

  ipAddress: null,
  checkoutUrl: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_TYPES.SETTINGS_FETCHED:
      return state.set('list', action.payload)

    case SETTINGS_TYPES.SETTING_FETCHED:
      return state.set('setting', action.payload)

    case SETTINGS_TYPES.SETTING_EXCHANGERATE:
      return state.set('exchangeRate', action.payload)

    case SETTINGS_TYPES.SETTING_DELIVERYPRICE:
      return state.set('deliveryPrice', action.payload)

    case SETTINGS_TYPES.SETTING_DELIVERYCONFIG:
      return state.set('deliveryConfigs', action.payload)

    case SETTINGS_TYPES.SETTING_CHECKOUT_URL:
      return state.set('checkoutUrl', action.url)

    case SETTINGS_TYPES.SETTING_DISCOUNT_ONLINE_PAYMENT:
      return state.set('discountOnlinePayment', action.payload)

    case SETTINGS_TYPES.SETTING_ATTENDANCE:
      return state.set('attendance', action.payload)

    case SETTINGS_TYPES.SETTING_UPDATE_ATTENDANCE:
      return state.set('attendance', action.payload)

    case SETTINGS_TYPES.SETTING_IPADDRESS:
      return state.set('ipAddress', action.payload)

    case SETTINGS_TYPES.SETTING_DEFAULT_LOCATION:
      return state.set('defaultLocation', action.payload)

    default:
      return state
  }
}
