/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { GET, POST } from 'fetchier'
import gq from '../queries'
import { NODE_URL } from '../config'
import {
  qTodayAttendance,
  qInsertAttendance,
  qAttendanceByDateRange,
} from '../../queries/attendances'
import { ATTENDANCE_TYPES } from '../reducers/attendances'

export const setTodayAttendance = (obj) => async (dispatch) => {
  try {
    const request = await gq.gqRequest(qInsertAttendance, { obj })

    if (!request) throw Error('Set Attendance not valid. Please try again')

    dispatch({
      type: ATTENDANCE_TYPES.SET_TODAY_ATTENDANCE,
      payload: {
        type: obj.type,
        data: obj,
      },
    })
  } catch (err) {
    console.log('setTodayAttendance', err)
  }
}

export const getTodayAttendance = () => async (dispatch, getState) => {
  try {
    const {
      data: { id: staffId },
    } = getState().auth

    const type = 'clock-in'
    const endOfDay = moment().endOf('day').toISOString()
    const startOfDay = moment().startOf('day').toISOString()

    const request = await gq.gqRequest(qTodayAttendance, { type, staffId, startOfDay, endOfDay })

    dispatch({
      type: ATTENDANCE_TYPES.SET_TODAY_ATTENDANCE,
      payload: {
        data: request.boorran_Attendances[0] || {},
        type: request.boorran_Attendances[0] ? request.boorran_Attendances[0].type : null,
      },
    })
  } catch (err) {
    console.log('getTodayAttendance', err)
  }
}

export const clockInAttendance = (payload, callback) => async (dispatch) => {
  try {
    const request = await POST({
      url: `${NODE_URL}/boorran/attendance/clock-in`,
      body: payload,
      headers: { 'Content-Type': 'application/json' },
    })

    if (request.statusCode !== 200) return callback('fail')

    return callback('success')
  } catch (err) {
    console.log('clockInAttendance', err)
  }
}

// export const requestAttendanceConfig = async () => {
//   try {
//     const request = await GET({
//       url: `${NODE_URL}/boorran/attendance/config`,
//       headers: { 'Content-Type': 'application/json' },
//     })

//     if (request.statusCode !== 200) throw Error('Error somethings. Please try again')

//     return request.data
//   } catch (err) {
//     console.log('requestAttendanceConfig', err)
//     alert(err.message || err)
//   }
// }

// export const getDeviceIPAddress = async () => {
//   try {
//     // Using axios instead of fetchier cause of axios support server-side API
//     const req = await axios.get('https://api.ipify.org?format=json')

//     return req.data.ip
//   } catch (err) {
//     console.log(err)
//   }
// }

export const getAttendanceByDateRange = async (startAt, endAt) => {
  try {
    const request = await gq.gqRequest(qAttendanceByDateRange, {
      startOfDay: startAt,
      endOfDay: endAt,
    })

    if (isEmpty(request.boorran_Attendances)) return null

    return request.boorran_Attendances
  } catch (err) {
    alert(err)
  }
}
