import Immutable from 'seamless-immutable'

export const SHIPPING_TYPES = {
  SHIPPINGS_FETCHED: 'SHIPPINGS_FETCHED',
}

const initialState = Immutable({
  list: [],
  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING_TYPES.SHIPPINGS_FETCHED:
      const { limit, offset, data, pages, page } = action.payload
      return state.set('list', data?.shipping).set('pagination', {
        limit,
        offset,
        pages,
        page,
        count: data?.shippingCount?.aggregate.count,
      })

    default:
      return state
  }
}
