const fields = `
  id
  type
  status
  staffId
  createdAt
  expiredAt
  ipAddress
  staff { 
    name, role, metadata 
    Orders(where: { _and: [
      { createdAt: { _gt: $startOfDay } },
      { createdAt: { _lt: $endOfDay } },
    ]}) { boorranGrandTotal isCollectedByAdmin status createdAt deliveryStatus }
  }
`

const qInsertAttendance = `
  mutation ($obj: [boorran_Attendances_insert_input!]!) {
    insert_boorran_Attendances(objects: $obj) {
      returning {
        id
        type
        status
        staffId
        createdAt
        expiredAt
        ipAddress
        staff { name, role, metadata }
      }
    }
  }
`

const qTodayAttendance = `
  query($staffId: uuid, $startOfDay: timestamptz, $endOfDay: timestamptz, $type: String) {
    boorran_Attendances(where: { _and: [
      { type:{ _eq: $type } },
      { staffId: { _eq: $staffId } },
      { createdAt: { _gt: $startOfDay } },
      { createdAt: { _lt: $endOfDay } },
    ]}) {
      ${fields}
    }
  }
`

const qAttendanceByDateRange = `
  query($startOfDay: timestamptz, $endOfDay: timestamptz) {
    boorran_Attendances(where: { _and: [
      { createdAt: { _gt: $startOfDay } },
      { createdAt: { _lt: $endOfDay } },
    ]}) {
      ${fields}
    }
  }
`

const qAttendanceByDateRangeAndStaff = `
  query($startOfDay: timestamptz, $endOfDay: timestamptz, $staffId: uuid) {
    boorran_Attendances(where: { _and: [
      { createdAt: { _gt: $startOfDay } },
      { createdAt: { _lt: $endOfDay } },
      { staffId: { _eq: $staffId } },
    ]}) {
      ${fields}
    }
  }
`

const qUpdateAttendance = `
  mutation ($id: uuid!, $fields: boorran_Attendances_set_input) {
    update_boorran_Attendances_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export {
  qInsertAttendance,
  qTodayAttendance,
  qAttendanceByDateRange,
  qAttendanceByDateRangeAndStaff,
  qUpdateAttendance,
}
