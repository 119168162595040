/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'
import { AUTH_TYPES } from '../types'

const initialState = Immutable({
  data: null,
  loading: true,
  error: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TYPES.SET_DATA:
      return state.set('loading', false).set('data', action.data)

    case AUTH_TYPES.SET_LOADING:
      return state.set('loading', action.loading)

    case AUTH_TYPES.SET_ERROR:
      return state.set('error', action.payload)
    default:
      return state
  }
}
