import Immutable from 'seamless-immutable'

export const TRANSACTION_TYPES = {
  TRANSACTIONS_FETCHED: 'TRANSACTIONS_FETCHED',
  TRANSACTION_FETCHED: 'TRANSACTION_FETCHED',
  SET_LOADING: 'SET_TRANSACTION_LOADING',
}

const initialState = Immutable({
  data: [],
  formData: {},
  loading: false,
  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_TYPES.TRANSACTIONS_FETCHED:
      const { data, limit, offset, count, pages, page } = action.payload

      return state
        .set('data', data)
        .set('loading', false)
        .set('pagination', { limit, offset, pages, page, count })

    case TRANSACTION_TYPES.TRANSACTION_FETCHED:
      return state.set('formData', action.payload).set('loading', false)

    case TRANSACTION_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    default:
      return state
  }
}

export default reducer
