const updateOrderByShopifyOrderNumber = `
  mutation UpdateOrder($orderNumber: String, $changes: boorran_Orders_set_input) {
    update_boorran_Orders(
      where: {shopifyOrderNumber: {_eq: $orderNumber}},
      _set: $changes)
    {
      affected_rows
    }
  }
`

const qUpdateCustomerOrder = `
  mutation updateBooranCustomer($customerId: uuid, $phone: String) {
    update_boorran_Customers(
      where: {
        id: {_eq: $customerId}
      },
        _set: {phone: $phone})
    {
      affected_rows
    }
  }
`

const qUpdateOrderStaff = `
  mutation($staffId: uuid, $orderId: uuid) {
    update_boorran_Orders(where: { id: { _eq: $orderId } }, _set: { staffId: $staffId }) {
      returning {
        id
        staffId
      }
    }
  }
`

const MUTATION_CANCEL_ORDER = `
  mutation($id: uuid) {
    update_boorran_Orders(
      where: { id: { _eq: $id } }
      _set: {
        deliveryStatus: "Pending",
        packStatus: "Pending"
        delivererId: null
        packBy: null
        schedule: false
      }
    ) {
      affected_rows
    }
  }
`

const MUTATION_REAPPOINT_ORDER = `
  mutation($id: uuid, $deliveryDate: timestamptz) {
    update_boorran_Orders(
      where: { id: { _eq: $id } }
      _set: {
        deliveryDate: $deliveryDate,
        deliveryStatus: "Waiting",
        delivererId: null
        schedule: false
      }
    ) {
      affected_rows
    }
  }
`

const MUTATION_PAYMENT_STATUS_ORDER = `
  mutation ($id: uuid, $paymentMethod: String, $isCollectedByAdmin: Boolean) {
    update_boorran_Orders(where: {id: {_eq: $id}}, _set: {paymentMethod: $paymentMethod, status: "paid", isCollectedByAdmin: $isCollectedByAdmin}) {
      affected_rows
    }
  }

`

export {
  qUpdateOrderStaff,
  qUpdateCustomerOrder,
  updateOrderByShopifyOrderNumber,
  MUTATION_CANCEL_ORDER,
  MUTATION_REAPPOINT_ORDER,
  MUTATION_PAYMENT_STATUS_ORDER,
}
