/* eslint-disable import/no-anonymous-default-export */
import Immutable from 'seamless-immutable'

export const USER_TYPES = {
  USERS_FETCHED: 'USERS_FETCHED',
  USER_FETCHED: 'USER_FETCHED',
  SET_LOADING: 'SET_LOADING',
}

const initialState = Immutable({
  user: {},
  list: [],
  pagination: { limit: 20, offset: 0, count: 0, pages: 1, page: 1 },
  loading: true,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.USERS_FETCHED:
      const { limit, offset, data, pages, page } = action.payload
      return state
        .set('list', data.users)
        .set('pagination', {
          limit,
          offset,
          pages,
          page,
          count: data.usersCount.aggregate.count,
        })
        .set('loading', false)

    case USER_TYPES.USER_FETCHED:
      return state.set('user', action.payload).set('loading', false)

    case USER_TYPES.SET_LOADING:
      return state.set('loading', action.payload)

    default:
      return state
  }
}
