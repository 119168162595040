const variants = {
  INVENTORY_POLICIES: {
    CONTINUE: 'CONTINUE',
    DENY: 'DENY',
  },
}

export default {
  defaultLocation: { lat: 11.54881915, lng: 104.91484695 },
  defaultCircleRadius: 300, // in meters
  sizes: ['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'],
  VARIANTS: variants,
}
