import { GET, POST } from 'fetchier'
import Cookie from 'js-cookie'
import { NODE_URL } from '../redux/config'

const sessionToken = Cookie.get('sessionToken')

export const requestRaksaDelivery = async (body) => {
  try {
    const req = await POST({
      url: `${NODE_URL}/boorran/delivery/raksa/create`,
      body,
      headers: { Authorization: sessionToken },
    })

    if (req.statusCode !== 200 || req.data.status_code) throw Error('Fail request RAKSA Delivery')

    return req.data
  } catch (err) {
    console.log('requestRaksaDelivery', err)
    throw err
  }
}

export const requestRaksaDeliveryFee = async (shopLocation, customerLocation, deliveryType) => {
  try {
    const type = Number(deliveryType) === 0 ? 'standard-fee' : 'calculate-distance'
    const body = {
      type,
      pick_up_lat: shopLocation.lat,
      pick_up_long: shopLocation.lng,
      drop_off_lat: Number(customerLocation[0]).toString(),
      drop_off_long: Number(customerLocation[1]).toString(),
    }

    const req = await POST({
      url: `${NODE_URL}/boorran/delivery/raksa/calculate-delivery-fee`,
      body,
      headers: { Authorization: sessionToken },
    })

    if (req.statusCode !== 200) throw Error('Fail request calculate delivery fee')

    const result = Number((req.data.delivery_fee / 4000).toFixed(2))

    return result
  } catch (err) {
    console.log('requestRaksaDeliveryFee', err)
    throw err
  }
}

export const requestDeliveryType = async () => {
  const req = await GET({
    url: `${NODE_URL}/boorran/delivery/raksa/delivery-type`,
    headers: { Authorization: sessionToken },
  })

  if (req.statusCode !== 200) throw Error('Fail request delivery type')

  const result = req.data.map((method) => ({
    key: method.id,
    text: method.name,
    value: method.id,
    disabled: method.name === 'Express',
  }))

  return result
}
