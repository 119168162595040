const CACHE_NAME = 'image-cache-v1'
const IMAGE_CACHE = [
  // Add static image URLs here if needed
]

// Install the service worker and cache images
self.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      console.log('Opened cache')
      return cache.addAll(IMAGE_CACHE)
    })
  )
})

// Intercept fetch requests
self.addEventListener('fetch', (event) => {
  if (event.request.destination === 'image') {
    event.respondWith(
      caches.match(event.request).then((response) => {
        return (
          response ||
          fetch(event.request).then((fetchedResponse) => {
            return caches.open(CACHE_NAME).then((cache) => {
              cache.put(event.request, fetchedResponse.clone())
              return fetchedResponse
            })
          })
        )
      })
    )
  }
})

// Clean up old caches
self.addEventListener('activate', (event) => {
  event.waitUntil(
    caches.keys().then((cacheNames) =>
      Promise.all(
        cacheNames.map((cacheName) => {
          if (cacheName !== CACHE_NAME) {
            return caches.delete(cacheName)
          }
        })
      )
    )
  )
})
