/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import { POST, wsGQLSubscribe, wsGQLUnsubscribe } from 'fetchier'
import Cookie from 'js-cookie'
import { isEmpty, sortBy } from 'lodash'
import libphonenumber from 'libphonenumber-js'
import { formatEnums, HASURA_WSS, NODE_URL } from '../config'

import { ORDER_TYPES } from '../reducers/orders'
import { SETTINGS_TYPES } from '../reducers/settings'

import gq from '../queries'
import { requestRaksaDelivery } from '../../services/delivery'
import { qProductImageByVariantId } from '../../queries/products'
import {
  MUTATION_CANCEL_ORDER,
  MUTATION_PAYMENT_STATUS_ORDER,
  MUTATION_REAPPOINT_ORDER,
  qUpdateCustomerOrder,
  qUpdateOrderStaff,
} from '../../queries/orders'

const sessionToken = Cookie.get('sessionToken')

const debug = false
const setLoading = (bool) => async (dispatch) =>
  dispatch({ type: ORDER_TYPES.SET_LOADING, payload: bool })

export const error = (err) => async (dispatch) => {
  dispatch({ type: ORDER_TYPES.ERROR, payload: err })
  console.error('Error with orders', err)
  dispatch(setLoading(false))
}

export const info = (obj) => async (dispatch) => {
  dispatch({ type: ORDER_TYPES.INFO, payload: obj })
}

export const addEnum = (props) => async (dispatch) => {
  const { value, name } = props
  const obj = { type: name, text: value, value }

  gq.gqRequest(gq.qUpsertEnum, { obj })
    .then((res) => dispatch(setEnums()))
    .catch((err) => dispatch(error(err)))
}

export const setEnums = () => async (dispatch) => {
  dispatch(fetchEnums())
    .then(({ Enums }) => {
      dispatch({
        type: SETTINGS_TYPES.SETTINGS_FETCHED,
        payload: Enums.filter((item) => item.type === 'paymentMethod'),
      })
      dispatch({
        type: ORDER_TYPES.ENUMS_FETCHED,
        payload: formatEnums([...Enums]),
      })
    })
    .catch((err) => dispatch(error(err)))
}

export const fetchEnums = () => async (dispatch) => gq.gqRequest(gq.qFetchEnums)

export const fetchUsers = () => async (dispatch) => gq.gqRequest(gq.qFetchUsers)

export const subscribeOrders =
  (opts = {}) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))

    const filters = (opts.filters && JSON.parse(decodeURI(window.atob(opts.filters)))) || {}

    let { limit = 20, offset, page = 1 } = opts || {}
    offset = (page && parseInt(page - 1, 10) * limit) || offset || 0

    const {
      settings: { list },
    } = getState()

    const isBank = list
      .filter(({ metadata }) => metadata && metadata.isBank)
      .map((item) => `"${item.value}"`)
      .join(', ')

    const isCashAccountant = list
      .filter(({ metadata }) => metadata && metadata.isCashAccountant)
      .map((item) => `"${item.value}"`)
      .join(', ')

    filters.isCashOnly = filters.isCashOnly ? isBank : false
    filters.isBankOnly = filters.isBankOnly ? isBank : false

    // eslint-disable-next-line no-unsafe-optional-chaining
    const { id, role } = getState().auth?.data
    const usersDriver = role === 'driver'
    const userId = id
    const driverFilterQuery = `
    waiting: boorran_Orders_aggregate(where:
    {
      deliveryStatus: {_eq: "Waiting"},
      _or: [
        {_and: {
          status: {_eq: "unpaid"},
          paymentMethod: {_eq: " Cash on Delivery (COD)"},
          _or: [
            {_and: { deliverBy: {_is_null: true}, delivererId: {_is_null: true}}},
            {delivererId: {_eq: "${userId}"}},
          ]
        }},
        {_and: {
          status: {_eq: "paid"},
          isCollectedByAdmin: {_eq: true},
          paymentMethod: {_in: [${isBank}]}
            _or: [
            {_and: { deliverBy: {_is_null: true}, delivererId: {_is_null: true}}},
            {delivererId: {_eq: "${userId}"}},
          ]
        }},
        {_and: {
          status: {_eq: "paid"},
          paymentMethod: {_in: [${isCashAccountant}]},
          paymentReceiverId: {_is_null: false},
            _or: [
            {_and: { deliverBy: {_is_null: true}, delivererId: {_is_null: true}}},
            {delivererId: {_eq: "${userId}"}},
          ]
        }}
      ]
    }) {
      aggregate{
        count
      }
    }
    assignedDeliver: boorran_Orders_aggregate(where:
    {
      delivererId: {_eq: "${userId}"},
      deliveryStatus: {_eq: "Delivering"},
      _or:
      [
        {_and: [{status: {_eq: "paid"}}, {paymentMethod: {_in: [${isBank}]}}]},
        {_and: [{status: {_eq: "paid"}}, {paymentMethod: {_in: [${isCashAccountant}]}}]},
        {_and: [{status: {_eq: "unpaid"}}, {paymentMethod: {_nin: [${isBank}]}}]}
    ]}
    ) {
        aggregate{
          count
        }
    },
    successDeliver: boorran_Orders_aggregate(where:
      {
        delivererId: {_eq: "${userId}"},
        paymentReceiverId: {_eq: "${userId}"},
        deliveryStatus: {_eq: "Done"},
        status: {_eq: "paid"},
        _or:
        [
          {
            paymentMethod: {_in: [${isBank}]},
            refundDeliveryToStaff: {_eq: "not_yet"},
            isCollectedByAdmin: {_eq: true},
          },
          {
            paymentMethod: {_nin: [${isBank}]}
          }
        ]
      }) {
          aggregate{
            count
          }
      },
      completedDeliver: boorran_Orders_aggregate(where:
        {
          delivererId: {_eq: "${userId}"},
          paymentReceiverId: {_eq: "${userId}"},
          deliveryStatus: {_eq: "Done"},
          status: {_eq: "paid"},
          _or: [
            {
                isCollectedByAdmin: {_eq: true},
                refundDeliveryToStaff: {_eq: "done"},
                paymentMethod: {_in: [${isBank}]}
            },
            {
                refundDeliveryToStaff: {_eq: "done"},
                paymentMethod: {_nin: [${isBank}]}
            }
          ]
        }) {
            aggregate{
              count
            }
        },
  `

    const appendQuery = usersDriver
      ? driverFilterQuery
      : `pending: boorran_Orders_aggregate(where: { status: { _in: ["pending", "Pre-order"] }}){
    aggregate{
      count
    }
  },
  preparing: boorran_Orders_aggregate(where: { status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _in: ["Pending", "Waiting", "Printing"] }}){
    aggregate{
      count
    }
  },
  unpaid: boorran_Orders_aggregate(where: { status: { _eq: "unpaid" }}){
    aggregate{
      count
    }
  },
  unpaidCash: boorran_Orders_aggregate(where: { status: { _in: ["unpaid", "pending"] }, paymentMethod: {_nin: [${isBank}]}, deliveryStatus: { _eq: "Pending" }}){
    aggregate{
      count
    }
  },
  unpaidBank: boorran_Orders_aggregate(where: { status: { _in: ["unpaid", "pending"] }, paymentMethod: {_in: [${isBank}]}}){
    aggregate{
      count
    }
  },
  unpack: boorran_Orders_aggregate(where:
    { _or:
      [
        {
          _and:[{status: { _eq: "paid" }}, {deliveryStatus: {_eq: "Pending"}}]
        },
        {
          _and:[{status: { _eq: "unpaid" }}, {deliveryStatus: {_eq: "Pending"}}]
        }
      ]
    }){
    aggregate{
      count
    }
  },
  unship: boorran_Orders_aggregate(where: { deliveryStatus: {_eq: "Waiting"}}){
    aggregate{
      count
    }
  },
  shipping: boorran_Orders_aggregate(where: { status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _eq: "Delivering" },
  _or: [
    {delivererId: {_is_null: false}},
    {deliverBy: {_is_null: false}}
  ]
  }){
    aggregate{
      count
    }
  },
  delivered: boorran_Orders_aggregate(where: { status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _eq: "Done" }, isCollectedByAdmin: { _eq: false }}){
    aggregate{
      count
    }
  },
  completed: boorran_Orders_aggregate(where: { _or: [{ status: { _in: ["cancelled", "exchanged", "refunded"] } }, { status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _eq: "Done" }, isCollectedByAdmin: { _eq: true } }] }){
    aggregate{
      count
    }
  },
  pendingByStaff: boorran_Orders_aggregate(where: { status: { _in: ["pending", "Pre-order"] }, staffId: {_eq: "${userId}" }}){
    aggregate{
      count
    }
  },
  `

    const action = (orders) => {
      gq.gqRequest(
        gq.qAggregate(
          {
            orderTab: opts.tab,
            filters,
            ordering: 'shopifyOrderId: desc',
            banks: isBank,
            appendQuery,
            userId,
            cashAccount: isCashAccountant,
          },
          undefined,
          'sum { boorranGrandTotal boorranDeliveryPrice grandTotal discount }'
        )
      )
        .then(
          ({
            boorran_Orders_aggregate,
            pending,
            unpack,
            unship,
            shipping,
            delivered,
            completed,
            unpaidBank,
            unpaidCash,
            waiting,
            assignedDeliver,
            successDeliver,
            completedDeliver,
            pendingByStaff,
          }) => {
            dispatch({
              type: ORDER_TYPES.ORDERS_FETCHED,
              payload: {
                limit,
                offset,
                page,
                pages: parseInt((boorran_Orders_aggregate.aggregate.count - 1) / limit + 1, 10),
                data: {
                  orders,
                  ordersCount: boorran_Orders_aggregate,
                  filterCount: {
                    pending: pending?.aggregate.count,
                    unpaidCash: unpaidCash?.aggregate.count,
                    unpaidBank: unpaidBank?.aggregate.count,
                    unpack: unpack?.aggregate.count,
                    unship: unship?.aggregate.count,
                    shipping: shipping?.aggregate.count,
                    delivered: delivered?.aggregate.count,
                    completed: completed?.aggregate.count,
                    waiting: waiting?.aggregate.count,
                    assignedDeliver: assignedDeliver?.aggregate.count,
                    successDeliver: successDeliver?.aggregate.count,
                    completedDeliver: completedDeliver?.aggregate.count,
                    pendingByStaff: pendingByStaff?.aggregate.count,
                  },
                },
              },
            })
          }
        )
        .catch((err) => dispatch(error(err)))
    }

    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'orders', debug })
    const subscription = {
      id: 'orders',
      query: gq.qSubscriptions({
        orderTab: opts.tab,
        filters,
        ordering: 'shopifyOrderId: desc',
        banks: isBank,
        userId,
        cashAccount: isCashAccountant,
      }),
      variables: { limit, offset },
      action,
    }
    return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
  }

export const subscribeSingleOrder = (id) => async (dispatch, getState) => {
  if (!id) {
    wsGQLUnsubscribe({ url: HASURA_WSS, id: 'order', debug })
    dispatch({ type: ORDER_TYPES.ORDER_FETCHED, payload: {} })
    return
  }

  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'order', debug })

  dispatch(setLoading(true))

  const action = (order) => dispatch({ type: ORDER_TYPES.ORDER_FETCHED, payload: order[0] })

  const subscription = {
    id: 'order',
    query: gq.itemIdById(id),
    action,
  }

  wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
}

const requestDeliveryService = async (order, customer, shopLocation, type) => {
  try {
    if (!order.location)
      throw Error('Order location not found, cannot calculate Raksa delivery fee')

    const pickupLocation = order.location.split(',')
    let packagePrice = Number(order.grandTotal)

    if (order.OriginalOrder) {
      const boorranGrandTotal =
        order.boorranGrandTotal -
        (order.OriginalOrder.grandTotal - order.OriginalOrder.deliveryPrice)

      packagePrice = Number(boorranGrandTotal.toFixed(2))
    }

    const body = {
      item_type: 8,
      description: order.note,
      package_price_currency: '0',
      package_price: packagePrice,
      delivery_type: Number(type),
      pick_up_lat: shopLocation.lat,
      pick_up_long: shopLocation.lng,
      recipient_phone: libphonenumber(customer.phone, 'KH').number,
      recipient_name: customer.firstName,
      drop_off_lat: Number(pickupLocation[0]).toFixed(6),
      drop_off_long: Number(pickupLocation[1]).toFixed(6),
    }

    const result = await requestRaksaDelivery(body)

    return result
  } catch (err) {
    alert(err.message)
    console.log(err)
  }
}

const getShopifyOrderDetail = async (shopifyId) => {
  const query = `
    query {
      order(id: "gid://shopify/Order/${shopifyId}") {
        id
        fulfillments {
          id
        }
      }
    }
  `
  const res = await gq.gqRequest(query)

  return res
}

const cancelOrderFulFillment = async (shopifyId) => {
  const { order } = await getShopifyOrderDetail(shopifyId)

  if (!order || isEmpty(order.fulfillments)) return true

  const mutation = `
    mutation {
      fulfillmentCancel(id: "${order.fulfillments[0].id}") {
        fulfillment {
          status
        }
        userErrors {
          field
          message
        }
      }
    }
  `
  const res = gq.gqRequest(mutation)

  return res
}

export const addTagToShopifyOrder = (shopifyId, authName) => async () => {
  const mutation = `
    mutation {
      orderUpdate(input: { id: "gid://shopify/Order/${shopifyId}", tags: ["Packed by ${authName}"] }) {
        order {
          tags
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  return gq.gqRequest(mutation).then((res) => console.log('res===', res))
}

export const updateOrder = (obj) => async (dispatch, getState) => {
  dispatch(setLoading(true))

  let order = Object.assign(Array.isArray(obj) ? [] : {}, obj)
  const { shopLocation } = getState().settings.deliveryConfigs
  let { customer, delivery, selectedDeliveryType, isCancelDelivery } = order

  const fieldsToRemove = [
    'staff',
    'source',
    'delivery',
    'customer',
    'createdAt',
    'orderItems',
    'customerId',
    'OriginalOrder',
    'paymentReceiver',
    'cancelRequests',
    'isCancelDelivery',
    'selectedDeliveryType',
    'paymentMethodRequests',
  ]

  fieldsToRemove.forEach((key) => delete order[key])

  let columns = Object.keys(order[0] || order).join(' ')
  const usersAdmin = getState().users?.list.filter((user) => user.role === 'admin')
  const arrayAdmin = usersAdmin.map((user) => user.id)

  if (!isCancelDelivery && delivery?.metadata?.isDeliveryService) {
    const deliveryOrderId = await requestDeliveryService(
      order,
      customer,
      shopLocation,
      selectedDeliveryType
    )

    if (!deliveryOrderId) throw Error('Cannot request Raksa Order')

    columns += ' deliveryOrderId'
    order = { ...order, deliveryOrderId: deliveryOrderId[0] ? deliveryOrderId[0] : null }
  }

  if (Array.isArray(obj)) {
    order = order.map((item) => {
      const { shopifyOrderId, shopifyOrderNumber, refundById } = getState().orders.list.find(
        ({ id }) => id === item.id
      )

      if (arrayAdmin.includes(refundById)) {
        return { ...item, refundById, shopifyOrderId, shopifyOrderNumber }
      }

      return { ...item, shopifyOrderId, shopifyOrderNumber }
    })
  }

  return new Promise((resolve, reject) => {
    gq.gqRequest(gq.qUpsert(undefined, columns), { obj: order })
      .then((res) => {
        dispatch(info({ res, message: 'Order updated successfully' }))
        resolve(res)
        dispatch(setLoading(false))
      })
      .catch((err) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('upsert error', err)
        dispatch(error(err))
      })
  })
}

export const subscribeSingleOrderActivityLog = (orderId) =>
  gq.gqRequest(gq.qActivityLog({ orderId })).then(({ boorran_Logs }) => boorran_Logs)

export const getOrderItems = (orderId) => (dispatch) =>
  gq.gqRequest(gq.qOrderItems({ orderId })).then(({ boorran_OrderItems }) => boorran_OrderItems)

export const removeOrderItem = (id) => (dispatch) => {
  const mutation = `
    mutation {
      delete_boorran_OrderItems_by_pk(id: "${id}") {
        id
      }
    }
  `
  return gq.gqRequest(mutation).then((res) => console.log('res===', res))
}

export const requestCancelOrder = async ({ shopifyOrderId, orderId, exchangedOrderId }) => {
  await cancelOrderFulFillment(shopifyOrderId)

  return POST({
    url: `${NODE_URL}/boorran/order/cancel`,
    body: { shopifyOrderId, orderId, exchangedOrderId },
    headers: { Authorization: sessionToken },
  })
}

export const createCancelRequest = (orderId) => async (dispatch, getState) => {
  const { id, name } = getState().auth.data

  const obj = {
    orderId,
    delivererId: id,
    remark: `Requested by ${name}`,
  }

  const mutation = gq.qUpsert('boorran_CancelRequests', ['id'], 'CancelRequests_pkey')

  return gq.gqRequest(mutation, { obj })
}

export const createOrder = (data) =>
  POST({
    url: `${NODE_URL}/boorran/order`,
    body: data,
    headers: { Authorization: sessionToken },
  })

export const subscribeModificationRequests = () => (dispatch, getState) => {
  wsGQLUnsubscribe({ url: HASURA_WSS, id: 'orders-modifications', debug })

  const subscription = {
    id: 'orders-modifications',
    query: gq.qModificationSubscription,
    action: (orders) => {
      const user = getState().auth.data

      let count = 0
      const requests = []

      orders.forEach((order) => {
        const [paymentRequest] = order.paymentMethodRequests
        const [cancelRequest] = order.cancelRequests

        if (order.paymentMethodRequests.length > 0 && ['finance', 'admin'].includes(user.role)) {
          count += 1
          requests.push({
            id: paymentRequest.id,
            orderId: order.id,
            deliverer: paymentRequest.deliverer.name,
            shopifyOrderId: order.shopifyOrderId,
            shopifyOrderNumber: order.shopifyOrderNumber,
            grandTotal: order.grandTotal,
            type: 'PAYMENT',

            paymentMethod: paymentRequest.paymentMethod,
            image: paymentRequest.image,
            createdAt: paymentRequest.created_at,
          })
        }

        if (
          order.cancelRequests.length > 0 &&
          (user.role === 'admin' || (user.role === 'finance' && cancelRequest.isReturn))
        ) {
          count += 1
          requests.push({
            id: cancelRequest.id,
            orderId: order.id,
            deliverer: cancelRequest.deliverer.name,
            shopifyOrderId: order.shopifyOrderId,
            shopifyOrderNumber: order.shopifyOrderNumber,
            grandTotal: order.grandTotal,
            type: cancelRequest.isReturn ? 'REAPPOINT' : 'CANCEL',

            isReturn: cancelRequest.isReturn,
            reappointDateTime: cancelRequest.reappointDateTime,
            remark: cancelRequest.remark,
            createdAt: cancelRequest.created_at,
          })
        }
      })

      dispatch({
        type: ORDER_TYPES.ORDER_MODIFICATIOS_FETCH,
        payload: { count, requests: sortBy(requests, 'createdAt') },
      })
    },
  }

  return wsGQLSubscribe({ url: HASURA_WSS, subscription, debug })
}

export const rejectModification = (request) => (dispatch) => {
  const table = request.type === 'PAYMENT' ? 'PaymentMethodRequests' : 'CancelRequests'
  const mutation = `mutation($id: uuid) {
    delete_boorran_${table}(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }`

  gq.gqRequest(mutation, { id: request.id })
}

export const updateCustomerOrder = (payload) => (dispatch) => {
  gq.gqRequest(qUpdateCustomerOrder, {
    customerId: payload.customerId,
    phone: payload.phone,
  })
}

export const approveModification = (request) => async (dispatch, getState) => {
  try {
    const user = getState().auth.data
    const TABLE = request.type === 'PAYMENT' ? 'PaymentMethodRequests' : 'CancelRequests'
    const MUTATION_APPROVE_REQUEST = `
      mutation($id: uuid) {
        update_boorran_${TABLE}(where: { id: { _eq: $id } } _set: { approved: true }) {
          affected_rows
        }
      }
    `

    await gq.gqRequest(MUTATION_APPROVE_REQUEST, { id: request.id })

    switch (request.type) {
      case 'PAYMENT':
        await gq.gqRequest(MUTATION_PAYMENT_STATUS_ORDER, {
          id: request.orderId,
          paymentMethod: request.paymentMethod,
          isCollectedByAdmin: user.role === 'admin',
        })
        break
      case 'REAPPOINT':
        await gq.gqRequest(MUTATION_REAPPOINT_ORDER, {
          id: request.orderId,
          deliveryDate: request.reappointDateTime,
        })
        break
      case 'CANCEL':
        await gq.gqRequest(MUTATION_CANCEL_ORDER, { id: request.orderId })
        await requestCancelOrder(request)
        break
      default:
        break
    }
  } catch (err) {
    alert(err.message || err)
    console.error('approveModification', err)
  }
}

export const getProductImages = (variantIds) => (dispatch) =>
  gq
    .gqRequest(qProductImageByVariantId, { variantIds })
    .then(({ boorran_ProductVariants }) => boorran_ProductVariants)

export const calculateDeliveryFee = (destination) => {
  const token = Cookie.get('sessionToken')

  return POST({
    url: `${NODE_URL}/boorran/calculate-delivery-fee`,
    body: { destination },
    headers: { Authorization: token },
  })
}

export const updateOrderStaffId = (orderId, staffId) => (dispatch) =>
  gq.gqRequest(qUpdateOrderStaff, { orderId, staffId }).then(({ boorran_Orders }) => boorran_Orders)
