/* eslint-disable import/no-cycle */
import map from './map'
import orders from './orders'
import helpers from './helpers'
import * as format from './format'
import constants from './constants'

export default {
  map,
  format,
  orders,
  helpers,
  constants,
}

// In utils.js or within your component
export const calculateSubTotal = (items) => {
  return items.reduce((total, item) => total + item.price * item.quantity, 0)
}
