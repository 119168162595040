/* eslint-disable no-unused-vars */
import Immutable from 'seamless-immutable'
import camelCase from 'lodash/camelCase'

export const ATTENDANCE_TYPES = {
  SET_TODAY_ATTENDANCE: 'SET_TODAY_ATTENDANCE',
}

const initialState = Immutable({
  data: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case ATTENDANCE_TYPES.SET_TODAY_ATTENDANCE:
      return state.setIn(['data', action.payload.type], action.payload.data)

    default:
      return state
  }
}
