const qExchangeRate = `
{
    exchangeRate: Enums_ExchangeRate(where: {}) {
      id
      riel
      dollar
    }
}
`
const qUpdateExchange = `
mutation ExchangeRate($id: uuid, $riel: Int) {
    update_Enums_ExchangeRate(where: {id: {_eq: $id},}_set: {riel: $riel}) {
        affected_rows
        returning{
            riel
            id
            dollar
        }
    }
}
`

const qDeliverer = `
{
    deliverer: Users(where: {
      role: {_eq: "driver"}
    }) {
      id
      name
      metadata
    }
    partner: Enums(where: {
      type: {_eq: "deliverBy"}
    }) {
      id
      text
      value
      metadata
    }
  }
`

const qUpdateDeliverPricebyUser = `
mutation($id: uuid, $metadata: jsonb) {
  update_Users(
    where: {id: {_eq: $id}}
    _set: {metadata: $metadata}
  ) {
    returning{
      id
      name
      metadata
    }
  }
}
`

const qUpdateDeliveryPricePartner = `
mutation($id: uuid, $metadata: jsonb) {
  update_Enums(
    where: {id: {_eq: $id}}
    _set: {metadata: $metadata}
  ) {
    returning{
      id
      text
      value
      metadata
    }
  }
}
`

const qFetchDeliveryPrice = `
query ($refId: uuid){
  deliveryPrice: Enums_DeliveryPrice(where: {
    referenceId: {_eq: $refId}
  }) {
    id
    price
  }
}
`

const qUpdateDelivery = `
mutation updateDeliveryPrice($obj: [Enums_DeliveryPrice_insert_input!]!){
  insert_Enums_DeliveryPrice(
    objects: $obj
    on_conflict: {
      constraint: DeliveryPrice_referenceType_referenceId_key,
      update_columns: [price]
    }
  ){
    affected_rows
  }
}
`

const qDeliveryConfig = `
query {
  boorran_Configs(where: { key: { _in: ["delivery", "shopLocation"] } }) {
    key
    value
  }
}
`

const qUpdateDeliveryConfig = `
mutation($key: String, $value: jsonb) {
  update_boorran_Configs(where: { key: { _eq: $key } } _set: { value: $value }) {
    returning {
      key
      value
    }
  }
}
`

const qCheckoutUrl = `
  query {
    boorran_Configs(where: { key: { _eq: "checkout_url" } }) {
      key
      value
    }
  }
`

const qGetDiscountOnlinePayment = `
  query {
    Enums(where: { type: { _eq: "discountOnlinePayment" } }) {
      text
      value
      metadata
    }
  }
`

const qUpdateDiscountOnlinePayment = `
  mutation($obj: Enums_set_input!) {
    update_Enums(where: { type: { _eq: "discountOnlinePayment" }}, _set: $obj) {
      returning {
        text
        value
        metadata
      }
    }
  }
`

const qAttendanceConfig = `
  query {
    boorran_Configs(where: {key: {_eq: "attendance"}}) {
      key
      value
    }
  }
`

const qUpdateAttendanceConfig = `
  mutation ($obj: boorran_Configs_set_input!) {
    update_boorran_Configs(where: {key: {_eq: "attendance"}}, _set: $obj) {
      returning {
        key
        value
      }
    }
  }
`

export {
  qDeliverer,
  qCheckoutUrl,
  qExchangeRate,
  qUpdateExchange,
  qUpdateDelivery,
  qDeliveryConfig,
  qAttendanceConfig,
  qFetchDeliveryPrice,
  qUpdateDeliveryConfig,
  qUpdateAttendanceConfig,
  qUpdateDeliverPricebyUser,
  qGetDiscountOnlinePayment,
  qUpdateDeliveryPricePartner,
  qUpdateDiscountOnlinePayment,
}
