import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import { composeWithDevTools } from '@redux-devtools/extension'
import rootReducer from './reducers'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const createReduxStore = () => {
  const store = createStore(rootReducer, composedEnhancer)
  return store
}

export default createReduxStore()
