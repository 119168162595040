import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AuthActions from '../redux/actions/auth'

const useAuthState = (selector, dispatch) => {
  const authState = useSelector(selector)

  useEffect(() => {
    dispatch(AuthActions.authenticate())
  }, [dispatch])

  return authState
}

export default useAuthState
