import Immutable from 'seamless-immutable'

export const NOTIFICATION_TYPES = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  FETCH_TODAY_NOTIFICATION: 'FETCH_TODAY_NOTIFICATION'
}

const initialState = Immutable({
  data: [],
  todayData: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.FETCH_NOTIFICATIONS:
      return state.set('data', action.payload)
    case NOTIFICATION_TYPES.FETCH_TODAY_NOTIFICATION:
      return state.set('todayData', action.payload)
    case NOTIFICATION_TYPES.CREATE_NOTIFICATION:
      return state.set('data', [...state.data, action.payload])
    case NOTIFICATION_TYPES.DELETE_NOTIFICATION:
      return state.set('data', action.payload)
    case NOTIFICATION_TYPES.UPDATE_NOTIFICATION:
      return state.set('data', action.payload)
    default:
      return state
  }
}
