import React, { createContext, useState, useMemo } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { orange, blue, red, green, grey } from '@mui/material/colors'

export const ColorModeContext = createContext({
  toggleMode: () => {},
  mode: 'light',
})

const theme0bj = {
  light: {
    background: {
      paper: 'rgb(255, 255, 255)',
      default: 'rgb(255, 255, 255)',
    },
  },

  dark: {
    background: {
      paper: 'rgb(0, 30, 80)',
      default: 'rgb(0, 30, 60)',
    },
  },
}

export const ColorContextProvider = ({ children }) => {
  const [mode, setMode] = useState('light')

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
      mode,
    }),
    [mode]
  )
  const theme = createTheme({
    palette: {
      mode,
      primary: {
        light: blue[800],
        main: blue[700],
        dark: blue[900],
        contrastText: '#fff',
      },
      secondary: {
        light: orange[800],
        main: orange[700],
        dark: orange[900],
        contrastText: '#fff',
      },
      error: {
        light: red[800],
        main: red[700],
        dark: red[900],
        contrastText: '#fff',
      },
      info: {
        light: blue[700],
        main: blue[600],
        dark: blue[800],
        contrastText: '#fff',
      },
      success: {
        light: green[800],
        main: green[700],
        dark: green[900],
        contrastText: '#fff',
      },
      default: {
        light: grey[800],
        main: grey[700],
        dark: grey[900],
        contrastText: '#fff',
      },

      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
      ...theme0bj[mode],
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: 1,
          },
          root: {
            // border: '1px solid #e0e0e3',
            // backgroundClip: 'padding-box',
          },
        },
      },
    },
  })
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}
