import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import moment from 'moment'
import { kebabCase } from 'lodash'

const uploadToFirebase = async ({ file, path = 'erp/images', name }) => {
  const storage = getStorage()
  const fileName = name || file.name
  const fullPath = `${path}/${fileName}`
  const fileRef = ref(storage, fullPath)

  await uploadBytes(fileRef, file)

  return getDownloadURL(fileRef)
}

const isObject = (val) => typeof val === 'object' && val !== null

export const classnames = (...args) => {
  const classes = []
  args.forEach((arg) => {
    if (typeof arg === 'string') {
      classes.push(arg)
    } else if (isObject(arg)) {
      Object.keys(arg).forEach((key) => {
        if (arg[key]) {
          classes.push(key)
        }
      })
    } else {
      throw new Error('`classnames` only accepts string or object as arguments')
    }
  })

  return classes.join(' ')
}

const discountStatus = (startAt, endAt) => {
  let status = ''

  const currentDate = Number(moment().format('x'))
  const endedAt = Number(moment(endAt).format('x'))
  const startedAt = Number(moment(startAt).format('x'))

  const isDiscountActive = moment().isBetween(startedAt, endedAt)

  if (isDiscountActive) {
    status = 'active'
  } else if (currentDate < endedAt) {
    status = 'pending'
  } else {
    status = 'expired'
  }

  return status
}

const batchUploadImages = async ({ images, path = 'products' }) => {
  try {
    return await Promise.all(
      images.map(async (img) => {
        const result = await uploadToFirebase({
          file: img.source,
          path: `${path}/${kebabCase(img.title)}`,
        })
        return result
      })
    )
  } catch (err) {
    alert('Error upload image')
    return console.error(err)
  }
}

const formatCountryPhoneNumber = (val, prefixCountry = '+855') => {
  return val.replace(/^\+?(0)/, prefixCountry)
}

export default {
  uploadToFirebase,
  classnames,
  discountStatus,
  batchUploadImages,
  formatCountryPhoneNumber,
}
