import Immutable from 'seamless-immutable'

export const STOCKB_TYPES = {
  FETCH_BEST_SELLERS: 'FETCH_BEST_SELLERS',
  FETCH_LOW_STOCK_VARIANTS: 'FETCH_LOW_STOCK_VARIANTS',

  SET_STOCK_CONFIGS: 'SET_STOCK_CONFIGS',
}

const initialState = Immutable({
  loaded: false,

  bestSellers: {},
  stockConfigs: {},
  lowStockVariants: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case STOCKB_TYPES.FETCH_LOW_STOCK_VARIANTS:
      return state.set('lowStockVariants', action.payload).set('loaded', true)

    case STOCKB_TYPES.FETCH_BEST_SELLERS:
      return state.set('bestSellers', action.payload)

    case STOCKB_TYPES.SET_STOCK_CONFIGS:
      return state.set('stockConfigs', action.payload)

    default:
      return state
  }
}
