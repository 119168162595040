import Immutable from 'seamless-immutable'

export const DISCOUNT_TYPES = {
  FETCH_DISCOUNTS: 'FETCH_DISCOUNTS',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  SET_PRODUCTS_DISCOUNTS: 'SET_PRODUCTS_DISCOUNTS',
}

const initialState = Immutable({
  data: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
    case DISCOUNT_TYPES.FETCH_DISCOUNTS:
      return state.set('data', action.payload)
    case DISCOUNT_TYPES.CREATE_DISCOUNT:
      return state.set('data', [...state.data, action.payload])
    case DISCOUNT_TYPES.DELETE_DISCOUNT:
      return state.set('data', action.payload)
    case DISCOUNT_TYPES.UPDATE_DISCOUNT:
      return state.set('data', action.payload)
    case DISCOUNT_TYPES.SET_PRODUCTS_DISCOUNTS:
      return state.set('data', action.payload)
    default:
      return state
  }
}
