/* eslint-disable arrow-body-style */
import moment from 'moment'

const formatDateTime = ({ timestamp, format = 'DD MM YY' }) =>
  timestamp ? moment(timestamp).format(format) : moment().format(format)

const addDay = ({ date, number }) => moment(date).add(number, 'days')

const sizeOrder = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL']

const customSortProduct = (variants) => {
  const data = variants.map((item) => ({
    ...item,
    size: item.title.split(' / ')[0] || '',
    color: item.title.split(' / ')[1] || '',
  }))
  data.sort(
    (first, second) =>
      first.color.toLowerCase().localeCompare(second.color.toLowerCase()) ||
      sizeOrder.indexOf(first.size) - sizeOrder.indexOf(second.size)
  )
  return data
}

const customDateString = (isoString) => {
  const options = {
    hour12: true,
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  }
  const orderDate = new Date(isoString)
  const today = new Date()
  const dateString = orderDate.toLocaleDateString('en-US', options).split(',')

  if (orderDate.getDate() === today.getDate()) dateString[0] = 'Today'
  if (orderDate.getDate() === today.getDate() - 1) dateString[0] = 'Yesterday'

  return dateString.join(',')
}

const calculateOrderGrandTotal = (order) => {
  const { grandTotal = 0, deliveryPrice = 0 } = order.OriginalOrder || {}

  const finalPrice = order.grandTotal - (grandTotal - deliveryPrice)

  return Number(parseFloat(finalPrice).toFixed(2))
}

const locationName = (addressComponents, state) => {
  let address = ''

  if (addressComponents.length <= 6 && state.long_name !== 'Phnom Penh') {
    address += `${addressComponents[0].long_name}, `
    address += `${addressComponents[1].long_name}, `

    if (addressComponents[3].types[0] !== 'country') {
      address += `${addressComponents[2].long_name}, `
      address += `${addressComponents[3].long_name}`
    } else {
      address += `${addressComponents[2].long_name}`
    }
  } else if (addressComponents.length <= 6) {
    address += `${addressComponents[0].long_name}, `
    address += `${addressComponents[1].long_name}, `
    address += `${addressComponents[2].long_name}`
  } else {
    address += `St ${addressComponents[0].long_name}, `
    address += `${addressComponents[1].long_name}, `
    address += `${addressComponents[2].long_name}, `
    address += `${addressComponents[3].long_name}`
  }

  return address
}

const sliceLocationName = (place) =>
  place.replace(/, Cambodia|Cambodia, |Cambodia|Province|Province/gi, '')

export {
  addDay,
  locationName,
  formatDateTime,
  customDateString,
  sliceLocationName,
  customSortProduct,
  calculateOrderGrandTotal,
}
